<template>
  <div class="p-grid p-jc-center p-ai-center vertical-container">
    <div
      class="p-col-12 p-md-10 card2 p-shadow-4 p-p-0 p-grid p-fluid"
      style="color: white"
    >
      <div class="p-col-12 p-md-6 headerBg p-jc-center p-d-flex">
        <img
          width="150px"
          height="150px"
          class="p-as-center p-d-none p-d-md-inline"
          style="
            object-fit: contain;
            cursor: pointer;
            -webkit-filter: drop-shadow(1px 1px 7px #000);
            filter: drop-shadow(1px 1px 7px #000);
          "
          :src="require('@/assets/bogorpools.png')"
        />
      </div>
      <div class="p-col-12 p-md-6 p-d-flex p-flex-column p-ai-center p-py-6">
        <h1>Login</h1>
        <div>
          <InputText v-model="nama" placeholder="Name" />
        </div>
        <div>
          <InputText
            class="p-mt-3"
            v-model="password"
            type="password"
            placeholder="Password"
          />
        </div>
        <div>
          <Button class="p-mt-3" label="Login" @click="login" />
        </div>
      </div>
    </div>
    <Toast />
  </div>
</template>

<script>
export default {
  data() {
    return {
      nama: "",
      password: "",
    };
  },
  methods: {
    setToken(token) {
      this.$toast.add({
        severity: "info",
        summary: "Confirmed",
        detail: "Record Deleted",
        life: 3000,
      });
      localStorage.token = token;
      this.$http.defaults.headers.common["Authorization"] = token;
      this.$store.commit("setUserName", this.nama);
      this.$router.push({ path: "/admin" });
    },
    async login() {
      await this.$http
        .post("/loginAdmin", {
          name: this.nama,
          password: this.password,
        })
        .then((r) => {
          this.$store.commit("setUserId", r.data.user.id);
          this.setToken(r.data.access_token);
        });
    },
  },
};
</script>


<style lang="scss">
@import "@/admin.scss";
.vertical-container {
  height: 100vh;
  width: 50vw;
}
</style>